@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.inter {
  font-family: "Inter", sans-serif;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.choosedAnswer {
  @apply border-green-500;
}
main {
  background: rgba(160, 152, 174, 0.1);
  border-radius: 10px;
}
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tab {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  @apply hover:bg-gray-200 duration-200 ease-in-out;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
